:root {
    /* --main-link-color: #11935b; */
    /* --main-link-color: #0f814a; */
    --main-link-color: #1c449b;
    --secondary-color: #3c3c47;
    /* --main-link-hover-color: #16bb9a36; */
    /* --main-link-hover-color: #11995763; */
    /* --main-link-hover-color: #3276868c; */
    --main-link-hover-color: #1c449b32;
    --dark-background-main: rgb(23, 34, 59);
    --dark-background-secondary: rgb(29,43,74);
}
html {
    overflow-x: hidden;
    overflow: scroll;
}
img {
    border: 0;
    display: block;
    height: auto;
    margin: 0 auto;
    max-height: 60px;
}
#root::-webkit-scrollbar {
    width: 15px;
}
#root::-webkit-scrollbar-track {
    background: #d4d4d4;
    scroll-margin-right: 5px;
}
#root::-webkit-scrollbar-thumb {
    background: rgb(163, 163, 163);
    border-radius: 1px;
  }
#root::-webkit-scrollbar-thumb:hover {
    background: rgb(141, 141, 141);
}
.flex {
    display: flex;
    flex-wrap: wrap;
}
.grid {
    display: grid;
}
.border {
    border: 1px rgba(0, 0, 0, 0.37) solid;
}
.fade.show {
    opacity: 1;
}
.fade {
    opacity: 0;
    transition: opacity .15s linear;
}
table {
    width: 100%;
    max-width: 100%;
    background-color: transparent;
}
.pointer {
    cursor: pointer;
}
.no-data {
    text-align: center;
    width: 100%;
    margin-top: 8%;
    color: #3c3c47;
}
/* DESIGN CSS Start */
.box {
    max-width: fit-content;
    position: absolute;
}
.box:hover {
  cursor:default;
  background-color: aliceblue;
}
.inputText {
    height: 15px;
    width: 30px;
}
/* DESIGN CSS End */

/* Top Level Start */
.top-level {
    position: relative;
    min-height: 90vh;
    /* padding-bottom: 50px; */
    overflow: hidden;
}
.content-body {
    margin-left: 235px;
    transition: margin-left .3s cubic-bezier(0.65, 0.05, 0.36, 1);
    overflow: hidden;
    margin-right: 10px;
    padding-bottom: 55px;
    max-height: 90vh;
    overflow: scroll;
}
/* Top Level End */

/* Side Navbar Start */
.side-nav-background {
    background: var(--secondary-color);
    height: 100vh;
    width: 200px;
    position: absolute;
    box-shadow: -7px 12px 10px 8px var(--secondary-color);
}
.side-nav-container {
    background-color: var(--secondary-color);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    color: white;
    width: 200px;
    transition: left 0.3s ease-in-out;
    z-index: 12;
}
/* Scroll over a text when it's too long */
.navbar-scoll {
    overflow: hidden;
    max-width: 125px;
}
.navbar-scroll:hover {
    max-width: fit-content;
    min-width: fit-content;
    -moz-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  
  -moz-animation: my-animation 4s linear infinite;
  -webkit-animation: my-animation 4s linear infinite;
  animation: my-animation 4s linear infinite;
}
/* End scroll */

@keyframes my-animation {
    from {
       -moz-transform: translateX(0%);
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
    to {
      -moz-transform: translateX(-125%);
      -webkit-transform: translateX(-125%);
      transform: translateX(-125%);
    }
}

/* -- Header  */
.user-information {
    line-height: 0;
    display: flex;
    flex-direction: row;
    padding: 5px;
}
.profile-img {
    max-width: fit-content;
    border-radius: 50%;
    margin: 0 5px 0 0;
}
.side-name {
    font-weight: 450;
    font-size: inherit;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 1;
}
.side-email {
    word-break: break-all;
    line-height: 1;
}
.account-info {
    align-self: flex-end;
}
.bottom-border {
    border-bottom: 1px solid lightgrey;
}
.logout-change {
    font-size: .75rem;
    color: rgba(255, 255, 255, 0.70);
}
.logout-change:hover {
    color: white!important;
    transition: color .2s ease-in-out;
}
/* -- Buttons */
.side-nav-button {
    width: 100%;
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
    color: rgba(255, 255, 255, 0.877)!important;
}
.side-nav-button span svg {
    font-size: large;
}
.active {
    background: linear-gradient(90deg, var(--main-link-color), transparent);
    color: #fff!important;
}
.side-nav-button:hover {
    background: linear-gradient(90deg, var(--main-link-hover-color), transparent);
    color: white!important;
    width: 100%;
    transition: .15s ease-in-out;
}
.active:hover {
    background: linear-gradient(90deg, var(--main-link-color), transparent);
}
/* -- Hidden Nav */
.hidden-nav {
    visibility: hidden;
    opacity: 0;
    background-color: var(--secondary-color);
    color: white;
    width: 100vw;
    text-align: start;
    z-index: 9;
}
.show-nav {
    left: 0!important;
    text-align-last: start;
}
.icon {
    transition: left .4s ease-out;
}
/* Side Nav Footer Start */
.side-nav-bar-footer {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}
.side-nav-bar-footer span a,
.side-nav-bar-footer span span {
    padding: 5px 0 5px 15px;
}
.side-nav-bar-footer span a,
.side-nav-bar-footer span span {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.61);
    width: auto;
    display: block;
}
.side-nav-bar-footer span > *:hover {
    color: white!important;
    cursor: pointer;
}
/* Need Help start */
.help-container {
    position: absolute;
    top: 120px;
    left: 25px;
    width: 150px;
    transition: transform .35s ease;
}
.help-container-top,
.help-container-btm,
.btm-cover {
    background-color: #3C3C47;
    color: var(--secondary-color);
}
.help-title {
    text-align-last: center;
    color: #cbcbe4;
    margin-top: 8px;
}
.help-container-top {
    z-index: 10;
    border: 1px solid #cbcbe4;
    border-radius: 15px;
    padding: 11px 0 0 0;
}
.help-container-btm {
    border: 1px solid #cbcbe4;
    width: 13%;
    height: 14px;
    transform: rotate(-45deg);
    z-index: 5;
    position: absolute;
    top: 0;
    left: 5%;
}
.btm-cover {
    height: 22px;
    width: 35px;
    left: 4%;
    border-top-left-radius: 10px;
    position: absolute;
    top: 1px;
    z-index: 6;
}
.help-pdf {
    z-index: 10;
    border: 1px solid #424463;
    background-color: #424463;
    border-radius: 5px;
    margin: 5px 5px;
    text-align-last: center;
    cursor: pointer;
}
.help-pdf a {
    color: #cbcbe4;
}
.help-pdf:hover {
    background-color: #626694;
}
/* Need Help end */
/* Side Nav Footer End */
/* Side Navbar End */

/* Home Start */
#home-header {
    padding-top: 20px;
    margin-bottom: 25px;
    font-size: 1.75rem;
    font-weight: 350;
}
.home-section {
    /* background-color: #81e3fc5d; */
    border-radius: 15px;
}
.home-inner-section {
    /* border-radius: 10px; */
    background-color: #a7a7a715;
    /* background-color: var(--secondary-color); */
    /* color: white; */
    border: 1px solid rgba(128, 128, 128, 0.486);
    padding: 10px;
    padding-right: 0;
    margin-bottom: 10px;
    max-width: 850px;
    margin: 30px;
    margin-left: 10px;
    margin-right: 0px;
    /* background-color: rgb(29,43,74); */
    /* box-shadow: #0000001a 0.4rem 0.7rem 1.25rem 0.5re inset; */
}
#home-invoices {
    display: flex;
    justify-content: space-between;
}
#home-invoices div {
    margin: 0px 30px;
}
.home-section .section-title {
    font-weight: 400;
}
@keyframes animate-bubble {
    0% {transform: translateY(0);}
    13% {transform: translateY(-1px);}
    26% {transform: translateY(-2px);}
    39% {transform: translateY(-1px);}
    52% {transform: translateY(0);}
    65% {transform: translateY(1px);}
    78% {transform: translateY(2px);}
    91% {transform: translateY(1px);}
    100% {transform: translateY(0);}
}
@keyframes box-glow {
    0% {box-shadow: 0 0 1rem .15rem rgba(63, 139, 75, 0.781);}
    25% {box-shadow: 0 0 1rem .28rem rgba(63, 139, 75, 0.781);}
    50% {box-shadow: 0 0 1rem .40rem rgba(63, 139, 75, 0.781);}
    75% {box-shadow: 0 0 1rem .28rem rgba(63, 139, 75, 0.781);}
    100% {box-shadow: 0 0 1rem .15rem rgba(63, 139, 75, 0.781);}
}
.bubble {
    border: .5px solid transparent;
    border-radius: 15px;
    height: 150px;
    width: 150px;
    box-shadow: 0 0 1rem .15rem #3f8b4bc7;
    vertical-align: middle;
    /* animation: box-glow 2s ease-in-out infinite both; */
    /* -webkit-font-smoothing: subpixel-antialiased; */
    flex-direction: column;
    justify-content: center;
    display: flex;
    background-color: rgba(120, 121, 120, 0.548);
}
.bubble > * {
    -webkit-font-smoothing: subpixel-antialiased;
}
.bubble:hover {
    box-shadow: 0 0 1rem .75rem rgba(63, 139, 75, 0.781);
    transition: .12s linear;
}
.bubble-header,
.bubble-footer {
    text-align: center;
    vertical-align: middle;
}
.bubble-header {
    font-weight: 500;
}
.bubble-footer {
    font-size: larger;
    font-weight: 400;
    margin-top: 0;
}
/* -- Account details */
.contact-container {
    padding: 5px 5px 5px 0;
    margin: 5px 0;
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 10px;
    align-items: center;
    text-align: left;
    max-width: 450px;
}
.contact-info {
    line-height: .2;
    align-items: flex-start;
}
#add-new-contact {
    cursor: pointer;
    background: ghostwhite;
}
#add-new-contact:hover {
    background: aliceblue;
}
.clicked {
    animation: glow 1s 1;
}
@keyframes glow {
    0% {box-shadow: 0;}
    50% {box-shadow: var(--main-link-color) 0 0 .75rem .5rem;} 
    100% {box-shadow: 0;}
}
.scroll-container {
    max-height: 400px;
    margin-top: -10px;
    margin-bottom: -10px;
    overflow: auto;
    overflow-x: hidden;
}
.scroll-container::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.scroll-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.scroll-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
/* -- -- Add User */
#add-user-form input,
#edit-user-form input {
    width: 100%;
    padding: 5px 0 5px 5px;
    margin-left: -5px;
}
#add-user-form,
#edit-user-form {
    border-bottom: none;
}
#add-user-form label,
#edit-user-form label {
    margin-top: 8px;
    font-size: larger;
}
.required {
    color: red;
    font-size: small;
}
#add-user-form .modal-footer,
#edit-user-form .modal-footer {
    border-top: 1px solid lightgrey;
    margin-top: 20px;
}
/* Home End */

/* Orders List Start */
.list-header {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    margin-bottom: 10px;
    background-color: white;
    border-bottom: 1px solid lightgrey;
}
.list-title {
    font-weight: 400;
    margin: 0;
}
ul[role=navigation] {
    display: flex;
    padding-left: 0;
}
ul[role=navigation] li {
    padding: 5px 12px;
    border-radius: 10px;
    color: var(--main-link-color);
    cursor: pointer;
}
ul[role=navigation] .disabled {
    background-color: white;
    color: #333;
    cursor: default;
}
ul[role=navigation] li:not(.disabled, .selected):hover {
    background-color: var(--main-link-hover-color);
}
ul[role=navigation] li.selected {
    background-color: var(--main-link-color);
    color: white;
}
#records-per-page {
    margin-right: 10px;
    font-size: .90em;
}
.list-header span  {
    align-self: center;
    padding-right: 5px;
    font-size: .95em;
}
.list-information {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    font-size: .90em;
    color: grey;
    text-align: right;
}
.order-list {
    display: grid;
}
.order {
    cursor: pointer;
}
.order:hover {
    background-color: var(--main-link-hover-color);
}
.link {
    color: var(--main-link-color);
    cursor: pointer;
}
.filter {
    cursor: pointer;
}
.filter:hover {
    box-shadow: 0 0 5px #9ecaed;
}
.filtered {
    color: #dd4c39;
}
.pdf {
    font-size: larger;
    padding: 8px 0 0 0!important;
    text-align: center;
}
.pdf:hover {
    color: red;
}
/* Orders List End */

/* Single Order Start */
.single-order-container,
.single-invoice-container,
.invoices-container,
.orders-container,
.statement-container {
    max-width: 1500px;
    width: 100%;
}
/* .single-order-container,
.single-invoice-container {
    padding-top: 10px;
} */
/* Single Order End */

/* Sale Order View Start */
.back-link {
    text-decoration: none;
    color: var(--main-link-color);
    align-items: center;
    cursor: pointer;
}
.tab {
    padding: 5px 15px;
    cursor: pointer;
    color: #428bca;
}
.active-tab {
    color: #495057;
    border-top: #002463 solid 3px;
    padding: 5px 15px;
    background: #dee2e6;
}
.active-tab,
.active-tab-content {
    background: #fafafa;
}
.display-none {
    display: none;
}
.table-header {
    list-style: none;
}
.table {
    text-align: left;
    border-collapse: collapse;
}
.table-wrapper {
    overflow: auto;
    width: 100%;
}
.table tbody tr th {
    vertical-align: top;
    text-decoration: none;
    font-weight: normal;
    padding: 10px 0;
    border-bottom: 1px solid #f7f7f7;
}
.table thead tr th {
    font-weight: 500;
    border: 1px solid #f7f7f7;
    border-left: 0;
    border-right: 0;
}
.single-order-bottom-container {
    padding: 50px;
    position: relative;
}

#logo_content {
    background : url(https://www.directics.com/wp-content/uploads/2022/04/dico-logo-update@2x.png);
}
.pcs-template-header,
.pcs-template-footer {
    margin: 0!important;
}
/* Sale Order View End */

/* Single Invoice Start */
.invoice-nav {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
}
.box-border {
    border: #c7cbcf solid 1px;
    border-radius: 3px;
}
.btn {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn:hover {
    background-color: rgb(192, 192, 192);
    border-color: #ddd;
    color: #333;
    box-shadow: 0 0 .1rem .1rem rgba(192, 192, 192, 0.473);
}
.btn-group > * {
    margin-left: 20px;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.ribbon {
    height: 125px;
    width: 210px;
    overflow: hidden;
    position: absolute;
    top: -4px;
    left: -5px;
}
.ribbon-inner {
    text-align: center;
    color: #FFF;
    top: 44px;
    left: -50%;
    width: 215px;
    font-size: 12px;
    padding: 4px 52px 4px 60px;
    position: relative;
    transform: rotate(-45deg);
}
.ribbon-overdue {
    background: red;
}
.ribbon-paid {
    background: #54a355;
}
.rma-container {
    border: 2px solid red;
}
.rma-container:hover {
    background-color: rgba(192, 4, 4, 0.911);
    box-shadow: 0 0 .1rem .1rem rgba(192, 4, 4, 0.911);
    border: 2px solid rgba(192, 4, 4, 0.911);
    color: white;
}
.disabled {
    background-color: grey;
    border: grey;
}
.disabled:hover {
    cursor:not-allowed;
}
/* Single Invoice End */

/* RMA Return Modal Start */
.modal-background {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0;
    background-color: rgba(51, 51, 51, 0.685);
    z-index: 20;
}
.modal-background-btn {
    position: absolute;
    right: 5%; 
    top: 15px;
    font-size: large;
    cursor: pointer;
    color: rgb(109, 108, 108);
}
.modal-container {
    position: fixed;
    top: 5%;
    left: 5%;
    right: 5%;
    margin: auto;
    max-width: 800px;
    height: 85vh;
    background: white;
    padding: 20px;
    border: 1px solid #ccc;
    transition: 0.5s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
    z-index: 50;
    border-radius: 5px;
    overflow:scroll;
    overflow-x: auto;
}
.modal-head {
    font-size: larger;
    font-weight: 500;
    padding-bottom: 5px;
    border-bottom: lightgrey 1px solid;
    width: 100%;
    text-align: center;
}
.modal-subhead {
    font-size: large;
    font-weight: 400;
    padding-bottom: 5px;
    width: 100%;
}
.modal-form {
    border-bottom: 1px lightgrey solid;
    padding: 16px;
    font-size: small;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
}
.form-items-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 5px;
}
.modal-btn {
    padding: 10px;
    background-color: transparent;
    cursor: pointer;
    transition: all .3s ease;
    border: 1px solid lightgrey;
}
/* .submit:hover {
    background: transparent!important;
    box-shadow: 0 0 .50rem 20px rgba(0, 128, 0, 0.575) inset, 0 0 .25rem 1px rgba(0, 128, 0, 0.575);
    color: white;
    transition: color .5s ease .3s, box-shadow .5s ease-in-out 0s, box-shadow ease .5;

    box-shadow: 0 0 .25rem 1px rgba(0, 128, 0, 0.575);
    transition: box-shadow .5s ease .5s;
}
.close:hover {
    background: transparent!important;
    box-shadow: 0 0 .50rem 20px rgba(128, 128, 128, 0.548) inset;
    transition: box-shadow .5s ease-in-out;
} */
/* -- Modal Footer Start */
.close {
    margin-left: 10px;
}
/* -- Modal Footer End */
/* -- Part List Start */
.part-container {
    display: table;
    width: 100%;
    margin-top: 10px;
    padding: 5px;
    border: 1px lightgrey solid;
}
.part-name {
    font-weight: 600;
    font-size: larger;
}
.part-selected {
    background-color: #dadee07c;
    /* background-color: #4e9edb3d; */
}
.part-items {
    display: table-row-group;
}
.part-items > * { 
    display: table-cell;
}
.part-info {
    max-width: 175px;
}
.sub-heading {
    color: grey;
    font-size: smaller;
    transition: .5s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.sub-heading-wrapper {
    transition: .2s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.sub-heading-wrapper:hover {
    cursor: pointer;
    background-color: var(--main-link-hover-color);
    border-radius: 5px;
    transition: .2s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.hide-heading {
    height: 20px;
    overflow: hidden;
    transition: .5s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.part-amt-wrapper {
    padding-left: 20px;
}
.part-amt-container {
    display: table;
}
.additional-info-btn {
    height: 20px;
    text-align: left;
}
.additional-info-btn:hover {
    cursor: pointer;
}
.expanded { 
    color: black;
    text-shadow: 1px 1px 2rem #2e8fda, 0 0 2rem #2e8fda, 0 0 2rem #2e8fda;
}
.plus-icon {
    padding-right: 5px;
    cursor: pointer;
    width: fit-content;
}
.plus-icon:hover {
    color: #2e8fda;
}
.part-amt-box {
    width: 45px;
    height: 18px;
    border: 1px black solid;
    text-align: center;
    margin-right: 3px;
}
.part-reason-box,
.part-country-box {
    text-align: start;
    width: 150px;
    transition: .5s cubic-bezier(0.22, 0.61, 0.36, 1);
    overflow: hidden;
}
.part-reason-box:focus {
    overflow: auto;
    height: 100px;
    transition: .5s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.part-row-header {
    text-align-last: start;
}
.part-row-header > * {
    font-weight: 400;
}
.extra-info-wrapper {
    box-shadow: var(--main-link-hover-color) 0px 4px 4px 0px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top: 10px solid transparent;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid transparent;
    transition: .2s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.align-right {
    text-align: right;
}
.extra-info-wrapper tr:hover,
.form-items-container:hover,
.blue-hover:hover {
    background-color: var(--main-link-hover-color);
    border-radius: 5px;
    transition: .2s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.extra-info-shown {
    position: absolute;
    left: 20%;
    background: white;
    transition: all .3s cubic-bezier(0.22, 0.61, 0.36, 1);
}
/* -- Part List End */
/* -- Document Upload Start */
.select-file {
    width: 100%;
    padding: 7px 25px;
    margin: 10px 0;
    color: rgb(134, 134, 134);
    border: 1px solid rgba(0, 0, 0, 0.336);
}
.select-file:hover {
    border: 1px solid rgba(0, 0, 0, 0.776);
}
#docu-uploaded-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
#docu-add-another {
    color: #2e8fda;
    cursor: pointer;
    text-align: initial;
}
/* -- Document Upload End */
.modal-footer {
    width: 100%;
    text-align: center;
    padding-top: 10px;
    margin-top: 5px;
}
.submit:hover {
    background-color: var(--main-link-hover-color);
}
/* RMA Return Modal End */



/* Request Account Info Start */
.form-show {
    left: 0;
    position: relative;
    transition: left .5s ease-in-out;
    margin: 20px;
}
.form-show > h2 {
    padding-bottom: 10px;
    border-bottom: 1px solid lightgrey;
}
.form-show p {
    margin-bottom: 0;
}
.form-hide {
    left: -500vw;
    position: fixed;

}
.form-back {
    transition: all .5s ease-in-out;
}
.account-container {
    border: 1px solid lightgray;
    border-radius: 5px;
    max-height: 250px;
    overflow: auto;
}
.info-container {
    border: 1px solid lightgray;
    background-color: var(--main-link-color);
    margin: 10px 0;
}
.account-name {
    padding: 5px;
}
.account-name:hover {
    /* background-color: #7e7e7e77; */
    cursor: pointer;
}
.account-selected {
    background-color: var(--main-link-hover-color);
    animation: glow 1s 1;
}
@keyframes glow {
    0% {box-shadow: 0;}
    50% {box-shadow: var(--main-link-hover-color) 0 0 .50rem .2rem;} 
    100% {box-shadow: 0;}
}
/* Request Account Info End */

/* Statement Start */
#date-range {
    width: 125px;
    height: 25px;
    border: 1px solid rgba(151, 150, 150, 0.623);
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: rgb(48, 48, 48);
    text-align: start;
    padding-left: 8px;
    cursor: pointer;
}
#date-range.filter-open {
    box-shadow: 0 0 .5rem var(--main-link-color);
}
.pay-filter-container {
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.445);
    border-radius: 8px;
    background-color: rgb(245, 245, 245);
    max-width: fit-content;
    margin-right: 10px;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease-in-out;
    z-index: 55;
}
.filter-open {
    visibility: visible;
    opacity: 1;
    transition: all .2s ease-in-out;
}
.react-datepicker-wrapper {
    width: fit-content!important;
}
.indiv-date-range {
    padding: 2px;
    padding-left: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    width: 150px!important;
    font-size: medium;
    margin: 4px;
    font-size: .9rem;
    color: black;
    cursor: pointer;
}
.indiv-date-range:hover {
    background-color: var(--main-link-color);
    color: white;
}
.pay-filter-itms {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: 350px;
    align-content: flex-start;
}
.pdf-view-container {
    max-width: fit-content;
    margin-top: 20px;
}
.pdf-page-num {
    text-align: right;
    border-bottom: 20px solid darkgrey;
    padding-right: 30px;
    font-size: .75em;
}
.react-pdf__Page__canvas {
    max-width: 800px;
    max-height: 1046px;
    margin: auto;
}
.react-pdf__Page {
    max-width: fit-content;
    position: static!important;
}
.react-pdf__Page__textContent {
    z-index: -1;
}
/* Statement End */
/* Password Change Form Start */
.password-form-label {
    font-size: large;
}
.password {
    width: 100%;
}
#password-rules {
    text-align: left;
    margin: auto;
}
.valid {
    color: green;
    text-decoration: line-through;
}
.not-valid {
    font-weight: 500;
}
/* Password Change Form End */

/* Loading component start */
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
    }
}
@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to { 
        -webkit-transform: rotate(360deg);
    }
}
.load {
	width: 50px;
	height: 50px;
	border: solid 5px #444146;
	border-radius: 50%;
	border-right-color: transparent;
	border-bottom-color: transparent;
	 -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name:             rotate; 
    -webkit-animation-duration:         1.0s; 
    -webkit-animation-iteration-count:  infinite;
    -webkit-animation-timing-function: linear;
    	
    transition: all 0.5s ease-in;
    animation-name:             rotate; 
    animation-duration:         1.0s; 
    animation-iteration-count:  infinite;
    animation-timing-function: linear; 
}
.loading > * {
    margin: 50px auto 0;
}
.hidden {
    display: none;
}
/* Loading component end */
/* Horizontal Load Start */
#horiz-load {
    width: 100%;
    overflow: hidden;
}
#horiz-load span {
    display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #aaaaaa;
  border-radius: 50%;
  animation: slide 2.25s cubic-bezier(.15, 0.64, 1, -.15) infinite;
  transform-origin: bottom center;
  position: relative;
}
#circle-1{
    animation-delay: -.9s!important;
 }
 #circle-2{
    animation-delay: -.7s!important;
  }
 #circle-3{
    animation-delay: -.5s!important;
  }
  #circle-4{
    animation-delay: -.3s!important;
  }
  #circle-5{
    animation-delay: -.1s!important;
  }
  #circle-6{
    animation-delay: -.2s!important;
  }
  #circle-7{
    animation-delay: -.1s!important;
  }
@keyframes slide {
    0%{
      transform: translateX(-100px);
    }
    100%{
      transform: translateX(750px);
    }
  }
/* Horizontal Load End */
/* Bar Load Start */
.bar-load {
    border: 1px lightgray solid;
    max-width: 220px;
    height: 30px;
    margin: auto;
}
.load-section {
    height: 30px;
    background-color: var(--main-link-color);
    transition: all .3s ease-in-out;
}
/* Bar Load End */

/* @media (min-width: 1150px) {
    .react-pdf__Page__canvas {
        width: 800px!important;
        height: 1000px!important;
    }
} */

/* Not found page Start */
#not-found-container {
    text-align: center;
}
#not-found-container h2 {
    font-weight: 400;
    font-size: xx-large;
    max-width: 300px;
    width: 100%;
    border-bottom: 1px solid darkgrey;
    padding-bottom: 20px;
    margin: auto;
    padding-top: 50px;
}
#not-found-container p {
    padding-top: 25px;
    font-size: large;
}
/* Not found page End */
/* Tracking Tab Start */
.tracking-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 30px 0px;
    max-height: 85vh;
    overflow: auto;
}
.tracking-container .box-container {
    width: 25%;
    min-width: 450px;
}
.box-container {
    margin: 0 10px;
    padding-top: 0px;
    border-radius: 5px;
    background-color: #fafafa;
    /* box-shadow: #3C3C47 0px 5px 8px 1px; */
}
.box-content {
    padding: 10px 0px;
    margin-bottom: 20px;
    height: 100%;
    overflow-y: auto;
    max-height: 725px;
}
.indiv-tracking {
    padding: 15px;
    background-color: white;
    margin: 8px;
    border: 1px solid rgba(230, 230, 230, 0.623);
    display: flex;
    justify-content: space-between;
}
.box-title {
    text-align: center;
    font-size: x-large;
    padding: 20px;
    padding-bottom: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.box-content .indiv-tracking .content-left,
.box-content .indiv-tracking .content-right {
    display: flex;
    flex-direction: column;
}
.sub-content {
    color: grey;
    font-size: small;
}
.box-content .indiv-tracking .content-right .sub-content {
    text-align: end;
}
.box-content .indiv-tracking .content-left {
    width: 35%;
}
.box-content .indiv-tracking .content-right {
    align-items: stretch;
    width: 100%;
}
/* Tracking Tab End */
@media (max-width: 768px) {
    .side-nav-container {
        left: -300px;
        transition: left .5s ease-in-out;
    }
    .content-body {
        transition: all .5s ease-in-out;
        margin: 10px;
        padding-top: 0;
    }
    .top-level .sign-on-wrapper {
        text-align: -webkit-center;
    }
    .hidden-nav {
        height: 50px;
        visibility: visible;
        opacity: 1;
        z-index: 10;
    }
    .extra-info-shown {
        left: 10%;
    }
}

@media (max-width: 600px) {
    .invoices-container,
    .orders-container,
    .single-order-container,
    .single-invoice-container {
        transition: all .5s cubic-bezier(0.65, 0.05, 0.36, 1);
    }
}

@media (max-width: 560px) {
    .modal-container {
        left: 0;
        right: 0;
        min-width: -webkit-fill-available;
        overflow: auto;
        padding: 20px 8px;
    }
    .modal-form {
        padding: 16px 5px 16px 1px;
    }
    .extra-info-shown {
        left: 0;
    }

    .single-order-bottom-container {
        padding: 0px;
    }
}

@media (max-width: 500px) {
    .sign-on-wrapper {
        padding: 10px 5px;
    }
    #home-invoices div {
        margin: 0px 5px;
    }
}

@media (max-width: 415px) {
    .sub-heading-wrapper {
        display: none;
    }
    .login-btn {
        width: 100%;
    }
    .indiv-date-range {
        width: auto!important;
        padding-right: 10px;
    }
}