:root {
	--background: #013CA6;
	--e-global-typography-80d2ed3-font-family: "Helvetica Neue";
--e-global-typography-80d2ed3-font-size: .95em;
--e-global-typography-80d2ed3-font-weight: 400;
--e-global-typography-80d2ed3-text-transform: uppercase;
--e-global-typography-80d2ed3-line-height: 1em;
}

ul, li {
	list-style: none;
}
a {
	text-decoration: none;
}

.mega-menu-link, .mega-menu-button {
	font-family: var( --e-global-typography-80d2ed3-font-family ),Sans-serif;
	font-size: var( --e-global-typography-80d2ed3-font-size );
	font-weight: var( --e-global-typography-80d2ed3-font-weight );
	text-transform: var( --e-global-typography-80d2ed3-text-transform );
	line-height: var( --e-global-typography-80d2ed3-line-height );
}

.nav-container {
	background: white;
	padding: 10px 25px;
	display: flex;
	justify-content: space-between;
	display: flex;
	align-items: center;
	box-shadow: -2px 5px 10px -2px #00000087;
	position: relative;
}

.nav-icons,
.menu-image {
	margin: 0 15px;
}

.mega-toggle-on {
	transition: all .25s ease;
}
.nav .container {
	margin: auto;
}
#mega-menu-primary-menu {
	display: inline-flex;
	flex-wrap: wrap;
	margin: 0;
	width: 100%;
	justify-content: center;
}
.mega-sub-menu .mega-menu-item .mega-menu-link {
	display: flex;
	color: black;
	text-decoration: none;
}
.mega-menu-button {
	font-family: helvetica neue,Sans-serif;
	text-transform: uppercase;
	line-height: 1em;
	fill: #fff;
	color: #fff;
	background-color: #1c449b;
	border-style: solid;
	border-width: 2px;
	border-color: var( --e-global-color-e017510 );
	border-radius: 7px 7px 7px 7px;
	padding: 10px;
	text-align: center;
transition: all .3s;
}

.mega-sub-link {
	padding: 10px;
	font-weight: 400;
	font-size: .90em;
}
.mega-sub-link:hover {
	background: #1c449b!important;
	color:white!important;
	transition: all .25s ease;
}

.mega-toggle-on .mega-sub-menu {
	visibility: visible;
}

#mega-menu-primary-menu > .mega-menu-item {
	padding: 0 10px;
}

/* Sub menus */
.mega-sub-menu {
	position: absolute;
	visibility: hidden;
	min-width: 150px;
	background-color: white;
	padding: 20px 0px 0px 0px;
	margin-left: 0;
	margin-right: 0;
    z-index: 100;
}

#mega-menu-wrap-primary-menu .mega-menu-toggle {
    display: none;
    cursor: pointer;
	margin-right: 5%;
    border-radius: 2px 2px 2px 2px;
    text-align: left;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    white-space: nowrap;
}

#mega-menu-primary-menu > li.mega-menu-item > button.mega-menu-link,
#mega-menu-primary-menu > li.mega-menu-item > a.mega-menu-link {
    vertical-align: baseline;
	background-color: transparent;
	border: none;
	cursor: pointer;
	color:black;
	text-decoration: none;
}
#mega-menu-wrap-primary-menu #mega-menu-primary-menu {
    text-align: center;
    padding: 0px;
}
.mega-toggle-label span .closed-icon {
	position: absolute;
	right: 15px;
	top: 15px;
}
.navbar-open {
	position: sticky;
	margin: 0;
	top: 0;
	height: 100vh;
	transition: height 1s ease-in;
	flex-direction: column;
	justify-content: flex-start;
}
.navbar-open .logo-section {
	margin: 55px;
}
.navbar-open .nav .mega-menu-link,
.navbar-open .nav .mega-menu-button {
	font-weight: 500;
}
@media (max-width: 1200px) {
    #mega-menu-primary-menu {
        justify-content:right;
    }
    .mega-menu-button {
        color: #1c449b;
        background-color: transparent;
        border: none;
    }
}
@media (max-width: 768px) {
	#mega-menu-wrap-primary-menu .mega-menu-toggle {
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		justify-content: flex-end;
	}
	.mega-menu {
		display: none!important;
	}
	.mega-menu-open {
		display: inline-flex!important;
		line-height: 2.5em;
		flex-direction: column;
		align-items: center;
	}
	#page-container .hdr .container .col-md-4 .logo-section a img {
		margin: auto;
	}
}
@media (max-width: 650px) {
	.mega-menu-open {
		flex-direction: column;
	}
	.mega-sub-menu {
		padding: 0px;
	}
	.mega-toggle-on .mega-sub-menu {
		display: block;
		text-align: center;
		position: relative;
	}
	.mega-sub-menu a:hover {
		background-color: white!important;
		color: black!important;
	}
	.mega-sub-link {
		color: #575757!important;
	}
	.mega-menu-item {
		padding: 0;
	}
	.nav-icons, .menu-image {
		margin: 0 15px;
	}
}