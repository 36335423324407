.data-box {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: white;
    border: 1px solid darkgrey;
    width: 275px;
    padding: 0 15px;
    z-index: 11;
    transition: height 1s ease-in-out;
}
.data-box h4 {
    padding: 7px 0 15px 0;
    margin: 0;
}
#loading-bar {
    width: 100%;
    border: 1px solid black;
    height: 20px;
    border-radius: 5px;
    background: var(--dark-background-main);
}
#data-box-show {
    height: 75px;
}
#data-box-hide {
    height: 0;
}