:root {
	--e-global-typography-aecd218-font-family: "Helvetica Neue";
	--e-global-typography-aecd218-font-size: 1em;
    --e-global-typography-aecd218-font-weight: 400;
    --e-global-typography-aecd218-line-height: 1.4em;
}
#main-footer {
	/* background-color: #051F52 !important; */
    /* NEW Background color as of September 2023 */
    background: linear-gradient(180deg,#1817A8 0%,#000000 100%);
}
img {
    height: auto;
    border: none;
    border-radius: 0;
    box-shadow: none;
}
#main-footer * {
	color: white;
}
#main-footer * > a span:hover {
	color: #1DAEEF;
}
#main-footer .container {
	padding: 5%;
}
.elementor-846396 .elementor-element.elementor-element-caa34b5>.elementor-element-populated {
    padding: 20px 0 0;
}
.elementor-element {
	--widgets-spacing: 20px;
}
.elementor-846396 .elementor-element.elementor-element-5cbcdb2 {
    --e-icon-list-icon-size: 14px;
}
.elementor-element {
    --widgets-spacing: 20px;
}
.elementor-widget-container a img {
    margin-left: 0;
}
.elementor-element, .elementor-lightbox {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
}
.elementor-widget {
    position: relative;
}
.elementor-section, .elementor-container {
	display: flex;
	margin-right: auto;
	margin-left: auto;
	position: relative;
    width: 100%;
    justify-content: space-between;
}
.elementor-element, .elementor-lightbox {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
}
.elementor-widget .elementor-icon-list-items {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.elementor *, .elementor :after, .elementor :before {
    box-sizing: border-box;
}
dd, dl, dt, li, ol, ul {
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}
.elementor-846396 .elementor-element.elementor-element-5cbcdb2 .elementor-icon-list-item>.elementor-icon-list-text, .elementor-846396 .elementor-element.elementor-element-5cbcdb2 .elementor-icon-list-item>a {
font-family: var(--e-global-typography-aecd218-font-family ),Sans-serif;
    font-size: var(--e-global-typography-aecd218-font-size );
    font-weight: var(--e-global-typography-aecd218-font-weight );
    line-height: var(--e-global-typography-aecd218-line-height );
    letter-spacing: var(--e-global-typography-aecd218-letter-spacing );
    word-spacing: var(--e-global-typography-aecd218-word-spacing );
}
.elementor-column {
    position: relative;
    min-height: 1px;
    display: flex;
}
.elementor-inner-section .elementor-column-gap-no .elementor-element-populated {
    padding: 0;
}
.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
    display: flex;
}
.elementor-widget-wrap {
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
}
.elementor-846396 .elementor-element.elementor-element-7ff397b {
    text-align: left;
}
.elementor-widget-wrap>.elementor-element {
    width: 100%;
}
.elementor-widget-image {
    text-align: center;
}
.elementor-element {
    --widgets-spacing: 20px;
}
.elementor-column {
    position: relative;
    min-height: 1px;
    display: flex;
}
.elementor-element {
    --flex-direction: initial;
    --flex-wrap: initial;
    --justify-content: initial;
    --align-items: initial;
    --align-content: initial;
    --gap: initial;
    --flex-basis: initial;
    --flex-grow: initial;
    --flex-shrink: initial;
    --order: initial;
    --align-self: initial;
    flex-basis: var(--flex-basis);
    flex-grow: var(--flex-grow);
    flex-shrink: var(--flex-shrink);
    order: var(--order);
    align-self: var(--align-self);
}
.elementor *, .elementor :after, .elementor :before {
    box-sizing: border-box;
}
.elementor-widget-container {
	line-height: 1.25em;
}
.elementor-123 {
    display: flex;
    align-content: space-between;
}
.elementor-element .elementor-widget-container {
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,transform var(--e-transform-transition-duration,.4s);
}
.elementor-widget:not(:last-child) {
	margin-bottom: 20px;
}
.elementor-icon-list-items {
	list-style: none;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.elementor-icon-list-item {
	padding-bottom: calc(10px/2);
	display: flex;
	align-items: center;
	font-size: inherit;
	position: relative;
}
.elementor-widget.elementor-list-item-link-full_width a {
    width: 100%;
	line-height: 1.75;
}
.elementor-grid-0 .elementor-grid {
    display: inline-block;
    width: 100%;
    word-spacing: var(--grid-column-gap);
    margin-bottom: calc(-1 * var(--grid-row-gap));
}
.attachment-full {
    max-width: 100%;
}
.elementor-social-icons-wrapper,
.elementor-element-9d09944 {
    text-align: right;
}
.elementor-icon i,
.elementor-icon svg {
    width: 1em;
    height: 1em;
    position: relative;
    font-size: 25px;
    padding: 3px;
}
.fa-brands, .fab {
    font-weight: 400;
}
.wp-image-847695 {
    max-height: fit-content;
}
@media (min-width: 768px) {
.elementor-column.elementor-col-50, .elementor-column[data-col="50"] {
    width: 50%;
}
.elementor-846396 .elementor-element.elementor-element-c2289b6 {
    width: 70%;
}
.elementor-846396 .elementor-element.elementor-element-0bd5410 {
    width: 30%;
}
.elementor-846396 .elementor-element-0b3ca07 {
    width: 30%;
}
.elementor-846396 .elementor-element.elementor-element-caa34b5 {
    width: 30%;
}
.elementor-column.elementor-col-25, .elementor-column[data-col="25"] {
    width: 25%;
}
.elementor-846396 .elementor-element.elementor-element-51d4406 {
    width: 25%;
}
}
@media (max-width: 1024px) {
.elementor-846396 .elementor-element.elementor-element-51d4406>.elementor-element-populated {
    padding: 30px 0 0;
}
.elementor-element-51d4406 {
    width: 100%!important;
}
.elementor-element-0bd5410 {
    width: 50%!important;
}
.elementor-element-caa34b5 {
    width: 30%!important;
}
.elementor-element-9b43ae4 {
    width: auto!important;
}
.elementor-container {
    flex-wrap: wrap;
}
.elementor-social-icons-wrapper,
.elementor-element-9d09944 {
    text-align: left;
}
}
@media (max-width: 767px) {
.elementor-column {
    width: 100%!important;
}
}