:root {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
}
.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    z-index: 2;
    margin-top: 10px;
    border-radius: 10px;
}
.card-id {
    font-size: 22px;
    font-weight: 500;
}
.card-click {
    color: #007bff;
}
.card-click:hover {
    text-decoration: underline;
}
.d-flex {
    display: flex;
}
.flex-grow {
    flex-grow: 1;
}
.flex-wrap {
    flex-wrap: wrap;
}
.f-column {
    flex-direction: column;
}
.text-center {
    text-align: center;
}
.font-weight-bold {
    font-weight: 700;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
}
.text-sm-right {
    text-align: right;
    font-size: .90em;
    justify-content: flex-end;
}
.lh-none {
    line-height: 0;
}
.justify-content-between {
    justify-content: space-between;
}
.justify-content-center {
    justify-content: center;
}
.justify-content-evenly {
    justify-content: space-evenly;
}
.justify-content-around {
    justify-content: space-around;
}
.track-border {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}
#tracking-container {
    max-width: 950px;
    margin-top: 5px;
}
/* Current - Completed Tabs */
#t-top-container {
    position: sticky;
    top: 0;
    background: white;
    z-index: 11;
}
#t-top-tabs span {
    border-bottom: 1px solid var(--secondary-color);
    padding-bottom: 9px;
    padding-top: 20px;
    text-align: center;
    width: 100%;
}
#t-top-tabs span:hover {
    cursor: pointer;
    font-weight: 600;
    border-bottom: 2px solid var(--main-link-color);
}
#t-top-tabs #active {
    font-weight: 600;
    border-bottom: 3px solid var(--main-link-color);
}
#tracking-slide {
    overflow: hidden;
    display: -webkit-box;
}
#tracking-slide > div {
    width: 100%;
}
/* Top Section */
.top {
    padding: 10px 20px;
    align-items: center;
    border-bottom: 1px dotted lightgrey;
}
.track-title {
    z-index: -1;
    transition: background .2s ease-in-out;
}
.packages {
    cursor: pointer;
}
.packages:hover,
.highlight .packages {
    background: aliceblue;
}
.highlight .packages {
    box-shadow: 0 5px 10px 0px lightgrey;
}
/* Content body after click */
.track-content {
    position: relative;
    overflow: hidden;
    margin: 0 2.5%;
    border-top: none;
    transition: all .3s ease-in-out;
}
.package-table {
    margin: 6px 2%;
    width: auto;
}
.package-table thead tr td {
    text-align: center;
    padding-bottom: 10px;
}
.package-table thead tr td span {
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 2px solid #0e46a6;
    padding: 2.5px;
}
.package-table tbody tr td:last-child {
    text-align: right;
    font-weight: 500;
}
.track-content #side-help {
    padding: 5px;
    background: #c5cae959;
    box-shadow: 0px 0 6px 0px inset darkgrey;
    display: flex;
    justify-content: space-evenly;
}
.track-content #side-help a {
    color: black;
}
.track-content #side-help a:hover {
    color: var(--main-link-color);
}
.package-table tbody tr .tracking {
    display: flex;
    flex-direction: column;
}
.package-table tbody tr .tracking .delivery {
    color: grey;
    font-size: .75em;
    font-weight: 400;
}
.package-table tbody tr .tracking-label {
    vertical-align: text-top;
}
/*Icon progressbar*/
#progressbar {
    overflow: hidden;
    color: #455A64;
    padding-left: 0px;
    margin-top: 30px;
    margin-bottom: 10px;
}

#progressbar li {
    list-style-type: none;
    font-size: 17px;
    width: 16%;
    float: left;
    position: relative;
    font-weight: 500;
}

#progressbar .step0:before {
    content: "";
    color: #fff;
}

#progressbar .track-current {
    font-weight: 600;
}

#progressbar li:before {
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: block;
    font-size: 25px;
    background: #C5CAE9;
    border-radius: 50%;
    margin: auto;
    padding: 0px;
}

/*ProgressBar connectors*/
#progressbar li:after {
    content: '';
    width: 100%;
    height: 12px;
    background: #C5CAE9;
    position: absolute;
    left: 0;
    top: 16px;
    z-index: -1;
}

#progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    left: -50%;
}

#progressbar li:nth-child(5):after {
    left: -50%;
}

#progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    left: 50%;
}

#progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

#progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

/*Color number of the step and the connector before it*/
#progressbar li.track-complete:before,
#progressbar li.track-complete:after {
    background: #0ea64c;
}
#progressbar li.track-error:before,
#progressbar li.track-error:after {
    background: #a60e0e;
}
#progressbar li.track-current:before,
#progressbar li.track-current:after {
    background: #0e46a6;
}

#progressbar li.track-complete:before {
    content: "✓";
}
#progressbar li.track-error:before {
    content: "!";
}
#progressbar li.track-current:before {
    content: "-";
}

@media (max-width: 768px) {
    #tracking-container {
        margin-top: 10px;
    }
}