#hero-text {
    padding: 50px 0;
    text-align: center;
    margin: auto;
    width: 50vw;
    min-width: 400px;
    max-width: 750px;
    max-height: fit-content;
}
#hero-img {
    width: auto;
    max-height: none!important;
    top: 0!important;
    position: absolute;
    overflow: hidden;
    z-index: -1!important;
    height: 1096px;
}
/* Login Start */
.sign-on-wrapper {
    margin: auto;
    background: rgba(255, 255, 255, 0.952);
    padding: 20px 0px;
    border-radius: 5px;
    max-width: 400px;
    box-shadow: 3px 5px 9px 3px #0b0b0b;
}
.title {
    text-align: center;
}

.sign-on-container > form {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
/* Reset the input CSS */
.sign-on-container > form > div > input,
.login-form-input > input {
    border: none;
    text-decoration: none;
    background-color: transparent;
    resize: none;
    outline: none;
}
.login-form-input {
    border: 1px solid black;
    justify-content: space-between;
    cursor: text;
    flex-wrap: nowrap;
    min-width: 200px;
    align-items: center;
    margin: 5px;
    border-radius: 3px;
}
.login-form-input > input {
    padding: 10px;
    width: 100%;
    height: 100%;
}
/* When entering login info */
.login-form-input:focus-within {
    border: 2px solid black;
}
.login-btn {
	fill: #fff;
	color: #fff;
	background-color: #1c449b;
	border-style: solid;
	border-width: 2px;
	border-color: var( --e-global-color-e017510 );
	border-radius: 7px 7px 7px 7px;
	padding: 3.5%;
    width: 100%;
    margin-top: 10px;
    margin-bottom: .5rem;
	text-align: center;
    font-weight: 400;
    font-size: .95em;
    transition: all .3s;
}
.login-btn:hover {
    background: #01399b8f;
    cursor: pointer;
}
.forgot-info {
    font-size: medium;
    color: #002463;
    text-decoration: none;
    text-align: center;
    padding: 5px;
    width: fit-content;
    margin: auto;
}
.forgot-info:hover {
    color: rgb(156, 156, 156);
}
.error-display {
    color: rgb(255 18 18);
    margin: 5px auto auto;
    padding: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
/* Login End */
/* Help Button Bottom Right Start */
#login-help-button {
    padding: 0px 10px;
    border: 1px solid black;
    background-color: white;
    border-radius: 50%;
    width: fit-content;
    color: black;
    font-size: xx-large;
    text-decoration: none;
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 999;
}
#login-help-button:hover {
    cursor: pointer;
    background-color: #1c449b;
    color: white;
    border: 1px solid white;
    transition: all .20s ease;
}
/* Help Button Bottom Right End */