.search {
    font-weight: 300;
    color: rgb(36, 36, 36);
    width: 100%;
}
.search {
    cursor: text;
}
.search input {
    height: 100%;
    width: fit-content;
    background: rgba(208, 208, 208, 0.206);
    padding: 5px 0;
    padding-left: 10px;
    border: 1px solid darkgray;
    transition: width .2s ease;
}
.search-pill input {
    border-radius: 10px;
}